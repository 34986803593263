export default {
  booking(data) {
    return window.$.ajax({
      method: 'POST',
      url: process.env.VUE_APP_API_URL.concat('/contact/booking'),
      data: JSON.stringify(data),
      contentType: 'application/json'
    });
  },
};
