require('./bootstrap');

import store from './state/store';
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'

import Router from 'vue-router';

const originalPush = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalPush.call(this, location).catch(err => err)
};

//click-out-side
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    //Tắt scroll khi bật popup cho mobile
    setTimeout(
      () => {
        document.body.classList.add("scroll-block");
        document.body.parentNode.classList.add('scroll-block')
      }, 100
    )

    el.eventSetDrag = function () {
        el.setAttribute('data-dragging', 'yes');
    }
    el.eventClearDrag = function () {
        el.removeAttribute('data-dragging');
    }
    el.eventOnClick = function (event) {
        var dragging = el.getAttribute('data-dragging');
        // Check that the click was outside the el and its children, and wasn't a drag
        if (!(el == event.target || el.contains(event.target)) && !dragging) {
            // call method provided in attribute value
            vnode.context[binding.expression](event);
        }
    };
    document.addEventListener('touchstart', el.eventClearDrag);
    document.addEventListener('touchmove', el.eventSetDrag);
    document.addEventListener('click', el.eventOnClick);
    document.addEventListener('touchend', el.eventOnClick);
  },
  unbind: function (el) {
    document.body.classList.remove("scroll-block");
    document.body.parentNode.classList.remove('scroll-block')
    document.removeEventListener('touchstart', el.eventClearDrag);
    document.removeEventListener('touchmove', el.eventSetDrag);
    document.removeEventListener('click', el.eventOnClick);
    document.removeEventListener('touchend', el.eventOnClick);
    el.removeAttribute('data-dragging');
  },
});

Vue.use(Router);

// import SlitherSlider from 'slither-slider';
// Vue.use(SlitherSlider)

new Vue({
  router,
  store,
  render: h => h(App),
  mounted(){

    setTimeout(() =>{

      // initFCSwiper();
      // initEvents();
      // initStyles();
      // initMap();
      initCollapseMenu();
      // checkCountUp();
      // initScrollReveal();
      scrollSmooth();
      $(document).resize()

    }, 500)


  }
}).$mount('#app');

