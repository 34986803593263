window.getCookieByName = function (name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

window.removeCookieByName = function (name) {
  if(window.getCookieByName(name)){
    document.cookie = name + "=;Max-Age=-99999999;";
  }
};
