import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import routes from './routes'
import { isUserLoggedIn, getUserData } from '@/utils/auth'
Vue.use(VueRouter);
Vue.use(VueMeta, {
  keyName: 'page',
});

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  },
});

router.beforeEach((to, _, next) => {
  return next()
})

export default router




