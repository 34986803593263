window.$ = window.jQuery = require('jquery');

window.$.ajaxSetup({
    headers: { 'x-api-token': localStorage.getItem('_token') }
});

let xhrOnlyLists = {};
window.$.ajaxOnce = function(url, options){
    if( xhrOnlyLists[url]){
        xhrOnlyLists[url].abort();
    }
    xhrOnlyLists[url] = window.$.ajax(url, options)
    return xhrOnlyLists[url]
}

window.$.getOnce = function( url, data, callback, type){
    if( xhrOnlyLists[url]){
        xhrOnlyLists[url].abort();
    }
    xhrOnlyLists[url] = window.$.get(url, data, callback, type)
    return xhrOnlyLists[url]
}

require('./utils/extensions');
require('./utils/constant');