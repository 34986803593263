import store from "@/state/store";

export default [{
    path: "*",
    beforeEnter: (from, to, next) => {
      next('/');
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('./pages/home'),
  },
]