Array.prototype.firstWhere = function (cb) {
  for (const item of this) {
    if (cb(item)) {
      return item;
    }
  }
  return null;
};

Array.prototype.findWhere = function (cb) {
  const ret = [];
  for (const item of this) {
    if (cb(item)) {
      ret.push(item);
    }
  }
  return ret;
};

Array.prototype.deleteWhere = function (cb) {
  for (const [index, item] of this.entries()) {
    if (cb(item)) {
      this.splice(index, 1);
    }
  }
};


String.prototype.getParameterByName = function (name) {
  name = name.replace(/[\[\]]/g, '\\$&'); // eslint-disable-line
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(this);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

String.prototype.truncate = function (length = 5, separator = '...') {
  if (this.length <= length) return this;

  separator = separator || '...';

  const sepLen = separator.length,
    charsToShow = length - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return this.substr(0, frontChars) + separator + this.substr(this.length - backChars);
};

String.prototype.formatCurrency = function () {
  return new Intl.NumberFormat('vi-VI', {style: 'currency', currency: 'VND' }).format(this).replace(/[₫]/gi, '')
    .replace(/(\.+\d{2})/, '').trim()
};

Number.prototype.formatNumber = function () {
  return new Intl.NumberFormat('vi-VI', { maximumSignificantDigits: 3 }).format(this)
};