import ContactApi from '../api/contact_api';

export const state = {};

export const mutations = {};

export const getters = {

};

export const actions = {
  booking(_, data) {
    return ContactApi.booking(data);
  },
};
